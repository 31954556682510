<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              absolute
              x-large
              right
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              @click="createBookPhoto"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-center">
            <span class="headline">Book photo details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form @submit.prevent="editMode ? update() : submit()">
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        accept="image/*"
                        label="Book photo"
                        v-model="photo"
                    ></v-file-input>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-btn
                        block
                        rounded
                        color="green"
                        class="mr-4"
                        type="submit"
                        :loading="loading"
                    >
                      {{ editMode ? 'update' : 'submit' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Photo
            </th>
            <th>
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="photo in book_photos"
              :key="photo.id"
          >
            <td>
              <v-img
                  :max-width="50"
                  :src="photo.photo"
              ></v-img>
            </td>
            <td>
              <v-btn icon color="blue" @click="edit(photo)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="red" @click="deleteBookPhoto(photo)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import Swal from "sweetalert2";

export default {
  components: {},
  data: () => ({
    loading: false,
    book_photos: [],
    selectedBookPhoto: null,
    editMode: false,
    dialog: false,
    photo: null,
    snackbar: false,
    text: '',
    color: 'blue',
  }),
  computed: {
    form: function () {
      let formData = new FormData();
      if (this.editMode) {
        formData.append('_method', 'PUT');
      } else {
        formData.append('book_id', this.$route.params.bookId)
      }
      if (this.photo) {
        formData.append('photo', this.photo);
      }
      return formData;
    }
  },
  methods: {
    createBookPhoto() {
      this.editMode = false;
      this.selectedBookPhoto = null;
      this.title = '';
      this.description = '';
      this.price = '';
      this.discount = '';
      this.photo = null;
    },
    edit(photo) {
      this.dialog = true;
      this.editMode = true;
      this.price = photo.price;
      this.selectedBookPhoto = photo;
    },
    submit() {
      const url = 'admin/book-photos';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New book photo created';
        this.loadBookPhotos();
      })
    },
    update() {
      const url = 'admin/book-photos/' + this.selectedBookPhoto.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Book photo updated';
        this.loadBookPhotos();
      })
    },
    loadBookPhotos() {
      const url = 'admin/book-photos?id=' + this.$route.params.bookId
      axios.get(url).then((response) => {
        this.editMode = false;
        this.book_photos = response.data.book_photos;
      })
    },
    deleteBookPhoto(book) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          const url = 'admin/book-photos/' + book.id;
          axios.delete(url).then(() => {
            this.loadBookPhotos();
            Swal.fire(
                'Deleted!',
                '',
                'success'
            )
          }).catch(() => {
            Swal.fire(
                'Failed!',
                'Something went wrong, try again',
                'warning'
            )
          })
        }
      });
    },
    clear() {
      this.photo = null;
      this.dialog = false;
      this.selectedBookPhoto = null;
    },
  },
  mounted() {
    this.loadBookPhotos();
  }
}
</script>